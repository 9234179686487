// ** Initial State
const initialState = {
    totalServices: 0,
    servicesTypeData: [],
    ServicesTypeAlertMessage: undefined,
    ServicesTypeAlertColor: undefined
}

const ServicesTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SERVICETYPE_DATA':

      return {
        ...state,
        servicesTypeData: action.servicesTypeData,
        totalServices: action.totalServices
      }
    case 'SET_SERVICETYPE_FLASH_MESSAGE':
        return {
            ...state,
            ServicesTypeAlertMessage: action.ServicesTypeAlertMessage,
            ServicesTypeAlertColor: action.ServicesTypeAlertColor
        }
    case 'ERASE_SERVICETYPE_DATA':
        return {
          }
    default:
      return state
  }
}

export default ServicesTypeReducer

// ** Initial State
const initialState = {
    totalPermission: 0,
    permissionData: [],
    PermissionAlertMessage: undefined,
    PermissionAlertColor: undefined
}

const PermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PERMISSION_DATA':

      return {
        ...state,
        permissionData: action.permissionData,
        totalPermission: action.totalPermission
      }
    case 'SET_PERMISSION_FLASH_MESSAGE':
        return {
            ...state,
            PermissionAlertMessage: action.PermissionAlertMessage,
            PermissionAlertColor: action.PermissionAlertColor
        }
    case 'ERASE_PERMISSION_DATA':
        return {
          }
    default:
      return state
  }
}

export default PermissionReducer

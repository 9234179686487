// ** Initial State
const initialState = {
    totalOrders: 0,
    orderData: [],
    orderAlertMessage: undefined,
    orderAlertColor: undefined
}

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDER_DATA':

      return {
        ...state,
        orderData: action.orderData,
        totalOrders: action.totalOrders
      }
      case 'SET_ORDER_FLASH_MESSAGE':
        return {
            ...state,
            orderAlertMessage: action.orderAlertMessage,
            orderAlertColor: action.orderAlertColor
        }
    case 'ERASE_ORDER_DATA':
        return {
          }
    default:
      return state
  }
}

export default OrderReducer

// ** Initial State
const initialState = {
    totalUserService: 0,
    userServiceData: [],
    userServiceAlertMessage: undefined,
    userServiceAlertColor: undefined
}

const UserServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_SERVICE_DATA':
      return {
        ...state,
        userServiceData: action.userServiceData,
        totalUserService: action.totalUserService
      }
      case 'SET_USER_SERVICE_FLASH_MESSAGE':
        return {
            ...state,
            userServiceAlertMessage: action.userServiceAlertMessage,
            userServiceAlertColor: action.userServiceAlertColor
        }
    case 'ERASE_USER_SERVICE_DATA':
        return {
          }

    default:
      return state
  }
}

export default UserServiceReducer

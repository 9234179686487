// ** Initial State
const initialState = {
  totalCtas: 0,
  ctaData: [],
  CtaAlertMessage: undefined,
  CtaAlertColor: undefined
}

const CtaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CTA_DATA':

      return {
        ...state,
        ctaData: action.ctaData,
        totalCtas: action.totalCtas
      }
    case 'SET_CTA_FLASH_MESSAGE':
      return {
        ...state,
        CtaAlertMessage: action.CtaAlertMessage,
        CtaAlertColor: action.CtaAlertColor
      }
    case 'ERASE_CTA_DATA':
      return {
      }
    default:
      return state
  }
}

export default CtaReducer

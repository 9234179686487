// ** Initial State
const initialState = {
    totalCompanies: 0,
    companyData: [],
    companyAlertMessage: undefined,
    companyAlertColor: undefined
}

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_COMPANY_DATA':

      return {
        ...state,
        companyData: action.companyData,
        totalCompanies: action.totalCompanies
      }
    case 'SET_COMPANY_FLASH_MESSAGE':
        return {
            ...state,
            companyAlertMessage: action.companyAlertMessage,
            companyAlertColor: action.companyAlertColor
        }
    case 'ERASE_COMPANY_DATA':
        return {
          }
    default:
      return state
  }
}

export default CompanyReducer

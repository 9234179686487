// ** Initial State
const initialState = {
    totalRole: 0,
    roleData: [],
    RoleAlertMessage: undefined,
    RoleAlertColor: undefined
}

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ROLE_DATA':

      return {
        ...state,
        roleData: action.roleData,
        totalRole: action.totalRole
      }
    case 'SET_ROLE_FLASH_MESSAGE':
        return {
            ...state,
            RoleAlertMessage: action.RoleAlertMessage,
            RoleAlertColor: action.RoleAlertColor
        }
    case 'ERASE_ROLE_DATA':
        return {
          }
    default:
      return state
  }
}

export default RoleReducer

// ** Initial State
const initialState = {
    totalPaypal: 0,
    totalStripe: 0,
    paypalData: [],
    stripeData: [],
    PaypalAlertMessage: undefined,
    StripeAlertMessage: undefined,
    PaypalAlertColor: undefined,
    StripeAlertColor: undefined
}

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_Paypal_DATA':

      return {
        ...state,
        paypalData: action.paypalData,
        totalPaypal: action.totalPaypal
      }
    case 'SET_Paypal_FLASH_MESSAGE':
        return {
            ...state,
            PaypalAlertMessage: action.PaypalAlertMessage,
            PaypalAlertColor: action.PaypalAlertColor
        }
    case 'ERASE_Paypal_DATA':
        return {
          }

    case 'GET_Stripe_DATA':

      return {
        ...state,
        stripeData: action.stripeData,
        totalStripe: action.totalStripe
      }
    case 'SET_Stripe_FLASH_MESSAGE':
        return {
            ...state,
            StripeAlertMessage: action.StripeAlertMessage,
            StripeAlertColor: action.StripeAlertColor
        }
    case 'ERASE_Stripe_DATA':
        return {
          }
    default:
      return state
  }
}

export default AccountReducer

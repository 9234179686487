// ** Initial State
const initialState = {
    totalCallBacks: 0,
    callBackData: [],
    CallBackAlertMessage: undefined,
    CallBackAlertColor: undefined
}

const CallBackReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CALLBACK_DATA':

      return {
        ...state,
        callBackData: action.callBackData,
        totalCallBacks: action.totalCallBacks
      }
    case 'SET_CALLBACK_FLASH_MESSAGE':
        return {
            ...state,
            CallBackAlertMessage: action.CallBackAlertMessage,
            CallBackAlertColor: action.CallBackAlertColor
        }
    case 'ERASE_CALLBACK_DATA':
        return {
          }
    default:
      return state
  }
}

export default CallBackReducer

// ** Initial State
const initialState = {
  total: 0,
  params: {},
  allData: []
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':

      return {
        ...state,
        allData: action.allData,
        total: action.totalPages
      }
    case 'ERASE_DATA':
        return {
          }
    default:
      return state
  }
}

export default DataTablesReducer

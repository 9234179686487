// ** Initial State
const initialState = {
    totalBranches: 0,
    branchData: [],
    branchAlertMessage: undefined,
    branchAlertColor: undefined
}

const BranchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BRANCH_DATA':

      return {
        ...state,
        branchData: action.branchData,
        totalBranches: action.totalBranches
      }
    case 'SET_BRANCH_FLASH_MESSAGE':
        return {
            ...state,
            branchAlertMessage: action.branchAlertMessage,
            branchAlertColor: action.branchAlertColor
        }
    case 'ERASE_BRANCH_DATA':
        return {
          }
    default:
      return state
  }
}

export default BranchReducer
